import React from 'react';
import './style.css';
import { FaPlane, FaHotel, FaCalendarAlt, FaMapMarkerAlt, FaTags } from 'react-icons/fa';

const BookingPlatform = () => {
    return (
        <div>
            <section className="booking-section">
                <h2 className="main-heading">Travel Booking Platform</h2>
                <div className="booking-options">
                    <div className="booking-option">
                        <FaPlane className="icon-booking" />
                        <h3>Flight Booking</h3>
                        <p>Book flights to your dream destinations, explore diverse accommodations, attend global events, and enjoy exclusive discounts on sightseeing tours.</p>
                    </div>
                    <div className="booking-option">
                        <FaHotel className="icon-booking" />
                        <h3>Hotel Booking</h3>
                        <p>Find comfortable accommodations for your trips, ranging from luxury hotels to budget-friendly options, ensuring a pleasant stay throughout your journey.</p>
                    </div>
                    <div className="booking-option">
                        <FaCalendarAlt className="icon-booking" />
                        <h3>Events Booking</h3>
                        <p>Discover and attend exciting events worldwide, ranging from concerts and festivals to sports events and cultural exhibitions.</p>
                    </div>
                    <div className="booking-option">
                        <FaMapMarkerAlt className="icon-booking" />
                        <h3>Tour Discount</h3>
                        <p>Enjoy exclusive discounts and deals on tour packages, ensuring affordable travel experiences tailored to your preferences and budget.</p>

                    </div>
                    <div className="booking-option">
                        <FaTags className="icon-booking" />
                        <h3>Sightseeing Booking</h3>
                        <p>Plan sightseeing tours to explore iconic landmarks, natural wonders, and cultural attractions in destinations around the globe.</p>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default BookingPlatform;
