// src/Components/AboutUs/AboutUs.js
import React from 'react';
import Mission from './Mission';
import Vision from './Vision';
import Values from './Values';
import Team from './Team';
import Services from './Services';
import banneImage from '../../assets/international.webp';
// import Testimonials from './Testimonials';
import './AboutUs.css'; // Import your custom CSS file

const AboutUs = () => {
    return (
        <div className="about-us">
            <header className="about-header">
                <img src={banneImage} alt="About Us" className="about-image" />
                <div className="overlay"></div>
                <h1>About Us</h1>
            </header>
            <Mission />
            <Vision />
            <Values />
            <Team />
            <Services />
            {/* <Testimonials /> */}
        </div>
    );
}

export default AboutUs;
