import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import request from '../../RequestHandler'; // Adjust the path to your request function
import Loader from '../../Components/Loader'; // Replace with your loader component
import './style.css'; // Import the CSS file for this component
import banneImage from '../../assets/international.webp';

const DestinationsPage = () => {
    const [destinations, setDestinations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const { destinations: data } = await request('GET', 'destinations-all');
                // Log the data to see its structure and contents
                console.log('API Response:', data);

                // Check if data is an array
                if (Array.isArray(data)) {
                    setDestinations(data);
                } else {
                    throw new Error('Data is not in expected format (not an array)');
                }
            } catch (error) {
                console.error('Error fetching destinations:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDestinations();
    }, []);

    if (loading) {
        return <Loader />; // Show loader component while loading
    }

    if (error) {
        return <p className="error-message">Error: {error}</p>;
    }

    return (
        <>
            <div className="container-fluid">
                <header className="about-header">
                    <img src={banneImage} alt="About Us" className="about-image" />
                    <div className="overlay"></div>
                    <h1>Explore Destinationss</h1>
                </header>
            </div>
            <div className="container mt-5">
                <div className="row">
                    {destinations.map(destination => (
                        <div className="col-md-4 mb-4" key={destination.id}>
                            <div className="card">
                                <img src={destination.featured_image} className="card-img-top" alt={`Destination ${destination.id}`} />
                                <div className="card-body">
                                    <h5 className="card-title">{destination.name}</h5>
                                    <p className="card-text">{destination.description}</p>
                                    <Link to={`/destination/${destination.id}`} className="btn btn-details">View Details</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <hr />

                <h2 className="mt-5 mb-4">Plan Your Next Adventure</h2>
                <p>
                    Discover amazing destinations around the world. Whether you're seeking serene beaches, adventurous mountains, or cultural experiences,
                    our destinations offer something for everyone.
                </p>

                <div className="row mt-4">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Beaches</h5>
                                <p className="card-text">Explore our top beach destinations with crystal-clear waters and sandy shores.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Mountains</h5>
                                <p className="card-text">Embark on thrilling mountain adventures with breathtaking views and hiking trails.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Cultural Cities</h5>
                                <p className="card-text">Immerse yourself in vibrant cultural cities rich in history, architecture, and local cuisine.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DestinationsPage;
