import React from 'react';
import './style.css'; // Import your custom CSS file
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHome, faInfoCircle, } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="footer-logo mb-4">
                            <img src="/logo.png" alt="Logo" className="img-fluid" />
                            <p className="footer-text">Your gateway to unforgettable journeys. Explore the world with us.</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer-links">
                            <h3>Quick Links</h3>
                            <ul className="list-unstyled">
                                <li><Link to="/" className="footer-link"><FontAwesomeIcon icon={faHome} /> Home</Link></li>
                                <li><Link to="/about" className="footer-link"><FontAwesomeIcon icon={faInfoCircle} /> About Us</Link></li>
                                {/* <li><Link to="/services" className="footer-link"><FontAwesomeIcon icon={faClipboardList} /> Services</Link></li> */}
                                <li><Link to="/contact" className="footer-link"><FontAwesomeIcon icon={faEnvelope} /> Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-contact">
                            <h3>Contact Us</h3>
                            <p className="footer-info"><FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /> 1st Floor, 7, D-109, Industrial Area, Sector 73, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                            <p className="footer-info"><FontAwesomeIcon icon={faPhone} className="icon" /> +91 86298-82193</p>
                            <p className="footer-info"><FontAwesomeIcon icon={faEnvelope} className="icon" /> info@ontravel.in</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-social">
                            <h3>Follow Us</h3>
                            <div className="social-icons">
                                <a href="https://www.facebook.com" className="social-icon" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a>
                                <a href="https://www.instagram.com" className="social-icon" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                                <a href="tel:+918629882193" className="social-icon"><FontAwesomeIcon icon={faPhone} /></a>
                                <a href="mailto:info@ontravel.in" className="social-icon"><FontAwesomeIcon icon={faEnvelope} /></a>
                                <a href="https://www.google.com/maps" className="social-icon" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMapMarkerAlt} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 ONTRAVEL. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
