import React, { useState, useEffect } from 'react';
import BannerSlider from './BannerSlider'
import PopularDestinations from './PopularDestinations';
import BookingPlatform from './BookingPlatform';
import WhyChooseUs from './WhyChooseUs';
import Testimonials from './Testimonials';
import request from '../../RequestHandler'; // Adjust the path to your request function
import Loader from '../../Components/Loader'; // Replace with your loader component

const Home = () => {
    const [destinations, setDestinations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const { destinations: data } = await request('GET', 'top-destinations');
                // Log the data to see its structure and contents
                console.log('API Response:', data);

                // Check if data is an array
                if (Array.isArray(data)) {
                    setDestinations(data);
                } else {
                    throw new Error('Data is not in expected format (not an array)');
                }
            } catch (error) {
                console.error('Error fetching destinations:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDestinations();
    }, []);

    if (loading) {
        return <Loader />; // Show loader component while loading
    }

    if (error) {
        return <p>Error: {error}</p>;
    }
    return (
        <div>
            <BannerSlider />
            <PopularDestinations destinations={destinations} />
            <BookingPlatform />
            <WhyChooseUs />
            <Testimonials />

        </div>
    )
}

export default Home
