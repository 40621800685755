import React from 'react';
import './Loader.css'; // Add CSS for loader styles

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <div className="loading-overlay">
                <img src="/logo.png" alt="On Travel Logo" className="logo" />
            </div>
        </div>
    );
};

export default Loader;
