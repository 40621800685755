// src/Components/ContactUs/ContactForm.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import contactImage from '../../assets/conatc.webp'; // Adjust the path as per your project structure

const ContactForm = () => {
    return (
        <section className="section contact-form">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src={contactImage} alt="Contact" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <h2>Contact Us</h2>
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Your Name</label>
                                <input type="text" className="form-control" id="name" placeholder="Enter your name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Your Email</label>
                                <input type="email" className="form-control" id="email" placeholder="Enter your email" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Your Phone Number</label>
                                <div className="input-group">
                                    <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number" required />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Your Message</label>
                                <textarea className="form-control" id="message" rows="5" placeholder="Enter your message" required></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactForm;
