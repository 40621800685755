// src/Components/AboutUs/Values.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import valuesImage from '../../assets/bali.webp'; // Update the path to your image

const Values = () => {
    return (
        <section className="section values">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src={valuesImage} alt="Values" className="img-fluid rounded" />
                    </div>
                    <div className="col-md-6">
                        <h2>Our Values</h2>
                        <h4>Principles We Live By</h4>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" /> Customer Satisfaction</li>
                            <li><FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" /> Integrity</li>
                            <li><FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" /> Innovation</li>
                            <li><FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" /> Quality</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Values;
