// AddressBar.js

import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';
import './style.css';

const AddressBar = () => {
    return (
        <div className="address-bar">
            <div className="address">
                <a href="https://maps.app.goo.gl/RCxZuUTCbhZYfG8A9" className="link"> <FaMapMarkerAlt className="icon" />
                    <span>    1st Floor, 7, D-109, Industrial Area, Sector 73, Sahibzada Ajit Singh Nagar, Punjab 160055</span></a>
            </div>
            <div className="divider"></div>
            <div className="contact-info">
                <div className="phone">
                    <a href="tel:+918629882193" className="link">  <FaPhone className="icon" />
                        +91 86298-82193</a>
                </div>
                <div className="divider"></div>
                <div className="email">
                    <a href="mailto:info@ontravel.in" className="link">  <FaEnvelope className="icon" />
                        info@ontravel.in</a>
                </div>
            </div>
            <div className="divider"></div>
            <div className="social-icons">
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className="icon facebook-icon" />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="icon instagram-icon" />
                </a>
            </div>
        </div >
    );
}

export default AddressBar;
