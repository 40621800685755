import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const request = async (method, endpoint, data = {}, token = null) => {
    try {
        const headers = {};
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await axios({
            method,
            url: `${BASE_URL}/${endpoint}`,
            data,
            headers,
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export default request;
