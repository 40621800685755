// src/Components/ContactUs/PhoneEmail.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const PhoneEmail = () => {
    return (
        <section className="phone-email py-5">
            <div className="container">
                <div className="row ">
                    <div className="col-md-12">
                        <p className="reach-us-text">
                            We're here to assist you in making your travel dreams a reality. Whether you have questions, need advice, or are ready to embark on your next adventure, don't hesitate to reach out to us. We look forward to hearing from you!
                        </p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <div className="contact-item">
                            <FontAwesomeIcon icon={faPhone} className="icon" />
                            <div className="text">
                                <h2>Call Us</h2>
                                <p>+91 86298-82193</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="contact-item">
                            <FontAwesomeIcon icon={faEnvelope} className="icon" />
                            <div className="text">
                                <h2>Email Us</h2>
                                <p>info@ontravel.in</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="contact-item">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                            <div className="text">
                                <h2>Visit Us</h2>
                                <p>1st Floor, 7, D-109, Industrial Area,</p>
                                <p>Sector 73, Sahibzada Ajit Singh Nagar,</p>
                                <p>Punjab 160055, India</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default PhoneEmail;
