// PopularDestinations.js
import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const PopularDestinations = ({ destinations }) => {
    const truncateDescription = (description) => {
        const lines = description.split('\n');
        return lines.slice(0, 3).join(' ');
    };

    return (
        <div>
            <section className="popular-destinations">
                <div className="container">
                    <h2>Popular Destinations</h2>
                    <div className="destination-grid">
                        {destinations.map(destination => (
                            <Link to={`/destination/${destination.id}`} key={destination.id} className="destination-link">
                                <div className="destination-card">
                                    <img src={destination.featured_image} alt={`Destination ${destination.featured_image}`} />
                                    <h3>{destination.name}</h3>
                                    <p>{truncateDescription(destination.description)}...</p>
                                    <button className="view-details-btn">View Details</button>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PopularDestinations;
