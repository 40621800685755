import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import request from '../../RequestHandler'; // Adjust the path to your request function
import Loader from '../../Components/Loader'; // Replace with your loader component
import './DestinationDetail.css'; // Add CSS file for DestinationDetail styles

const DestinationDetail = () => {
    const { id } = useParams();
    const [destination, setDestination] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDestination = async () => {
            try {
                const { destination: data } = await request('GET', `destinations/${id}`);
                // Log the data to see its structure and contents
                console.log('Destination Detail API Response:', data);
                setDestination(data);
            } catch (error) {
                console.error('Error fetching destination details:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDestination();
    }, [id]);

    if (loading) {
        return <Loader />; // Show loader component while loading
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!destination) {
        return <p>No destination found.</p>; // Handle case where destination is not loaded
    }

    return (
        <div className="destination-detail">
            <div className="header">
                <h2 className="title">{destination.name}</h2>
                <h3 className="sub-heading">{destination.sub_heading}</h3>
            </div>
            <div className="image-container">
                <img src={destination.featured_image} alt={destination.name} className="featured-image" />
            </div>
            <div className="description">
                <p>{destination.description}</p>
            </div>
            <div className="details">
                <p><strong>State:</strong> {destination.state_name}</p>
                <p><strong>Created At:</strong> {new Date(destination.created_at).toLocaleString()}</p>
                {/* Add more details as needed */}
            </div>
            <div className="gallery">
                <h3 className="gallery-heading">Gallery</h3>
                <div className="gallery-images">
                    {destination.gallery.map((image, index) => (
                        <img key={index} src={image} alt={`Gallery Image ${index + 1}`} className="gallery-image" />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DestinationDetail;
