import React from 'react';
import { Container, Carousel, Card, Button } from 'react-bootstrap';

import male1Image from '../../../assets/paulo-sacramento.1024x1024-1.jpg';
import male2Image from '../../../assets/images_test.jpeg';
import female1Image from '../../../assets/photo-1609902726285-00668009f004.jpeg';
import female2Image from '../../../assets/imagestest_3.jpeg';
import './style.css'; // Import your custom CSS file

const Testimonials = () => {
  const testimonials = [
    {
      name: 'John Doe',
      image: male1Image,
      feedback: 'Excellent service! ONTRAVEL made our trip unforgettable.'
    },
    {
      name: 'Jane Smith',
      image: female1Image,
      feedback: 'Beautiful experience with ONTRAVEL. Highly recommended.'
    },
    {
      name: 'Michael Brown',
      image: male2Image,
      feedback: 'Great tour guides and seamless booking process.'
    },
    {
      name: 'Emily Davis',
      image: female2Image,
      feedback: 'I loved every moment of the trip. Thank you, ONTRAVEL!'
    },
    {
      name: 'Robert Johnson',
      image: male1Image,
      feedback: 'Exceptional customer support and attention to detail.'
    },
    {
      name: 'Jessica Miller',
      image: female1Image,
      feedback: 'Fantastic experience. ONTRAVEL exceeded my expectations.'
    }
  ];

  return (
    <section className="testimonials-section" style={{ backgroundColor: 'rgb(38, 131, 195', color: '#ffffff' }}>
      <Container>
        <h2 className="text-center mb-4">Guest Feedback</h2>
        <Carousel interval={5000} pause="hover" nextIcon={<span className="carousel-control-next-icon" aria-hidden="true" />} prevIcon={<span className="carousel-control-prev-icon" aria-hidden="true" />}>
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <Card className="testimonial-card">
                <Card.Body>
                  <div className="testimonial-content">
                    <p className="testimonial-text">{testimonial.feedback}</p>
                    <p className="testimonial-author">{testimonial.name}</p>
                  </div>
                  <div className="testimonial-img-container">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="testimonial-img"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="carousel-controls">
          <Button variant="primary" className="carousel-control-prev">
            Prev
          </Button>
          <Button variant="primary" className="carousel-control-next">
            Next
          </Button>
        </div>
      </Container>
    </section>
  );
}

export default Testimonials;
