import React, { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import request from '../../RequestHandler';

const BookingForm = () => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        date: null,
        persons: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const validator = useRef(new SimpleReactValidator());

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleDateChange = (date) => {
        setForm({
            ...form,
            date: date,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            setIsSubmitting(true);
            try {
                const formattedDate = format(form.date, 'yyyy-MM-dd HH:mm:ss');  // Format the date
                const response = await request('POST', 'bookings', {
                    name: form.name,
                    email: form.email,
                    phone_number: form.phone,
                    date: formattedDate,
                    number_of_persons: form.persons,
                });
                toast.success('Booking created successfully!');
                // Reset the form
                setForm({
                    name: '',
                    email: '',
                    phone: '',
                    date: null,
                    persons: ''
                });
            } catch (error) {
                toast.error(`Error: ${error.message}`);
            } finally {
                setIsSubmitting(false);
            }
        } else {
            validator.current.showMessages();
            // Rerender to show messages for the first time
            setForm({ ...form });
        }
    };

    return (
        <div className="booking-form">
            <h3>Book Your Trip</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            className={`form-control ${validator.current.message('name', form.name, 'required') ? 'is-invalid' : ''}`}
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                        />
                        {validator.current.message('name', form.name, 'required')}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            className={`form-control ${validator.current.message('email', form.email, 'required|email') ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                        />
                        {validator.current.message('email', form.email, 'required|email')}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="phone">Phone Number:</label>
                        <input
                            type="tel"
                            className={`form-control ${validator.current.message('phone', form.phone, 'required|phone') ? 'is-invalid' : ''}`}
                            id="phone"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                            placeholder="Enter your phone number"
                        />
                        {validator.current.message('phone', form.phone, 'required|phone')}
                    </div>
                    <div className="form-group">
                        <label htmlFor="date">Date:</label>
                        <DatePicker
                            className={`form-control ${validator.current.message('date', form.date, 'required') ? 'is-invalid' : ''}`}
                            selected={form.date}
                            onChange={handleDateChange}
                            placeholderText="Select date"
                            dateFormat="MMMM d, yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                        {validator.current.message('date', form.date, 'required')}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="persons">Number of Persons:</label>
                        <input
                            type="number"
                            className={`form-control ${validator.current.message('persons', form.persons, 'required|numeric') ? 'is-invalid' : ''}`}
                            id="persons"
                            name="persons"
                            value={form.persons}
                            onChange={handleChange}
                            placeholder="Enter number of persons"
                        />
                        {validator.current.message('persons', form.persons, 'required|numeric')}
                    </div>
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
            </form>
        </div>
    );
}

export default BookingForm;
