// src/Components/AboutUs/Team.js
import React from 'react';
import teamImage from '../../assets/team.png'; // Update the path to your image

const Team = () => {
    return (
        <section className="section team">
            <div className="container">
                <div className="row align-items-center flex-md-row-reverse">
                    <div className="col-md-6">
                        <h2>Meet the Team</h2>
                        <h4>Passionate Travel Experts</h4>
                        <p>Our team consists of experienced and passionate travel professionals dedicated to making your journey memorable. We work tirelessly to plan and execute the perfect trip for you.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={teamImage} alt="Team" className="img-fluid rounded" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Team;
