// src/Components/AboutUs/Vision.js
import React from 'react';
import visionImage from '../../assets/Rajasthan.jpg'; // Update the path to your image

const Vision = () => {
    return (
        <section className="section vision">
            <div className="container">
                <div className="row align-items-center flex-md-row-reverse">
                    <div className="col-md-6">
                        <h2>Our Vision</h2>
                        <h4>Seeing Beyond the Horizon</h4>
                        <p>We envision a world where travel is accessible, affordable, and enjoyable for everyone. Our goal is to connect people through extraordinary travel experiences that inspire and transform.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={visionImage} alt="Vision" className="img-fluid rounded" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Vision;
