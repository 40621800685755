// src/Components/AboutUs/Services.js
import React from 'react';
import servicesImage from '../../assets/inbg.jpg'; // Update the path to your image

const Services = () => {
    return (
        <section className="section services">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2>Our Services</h2>
                        <h4>What We Offer</h4>
                        <p>We offer a wide range of travel services including customized tours, travel planning, and 24/7 customer support. Whether you're looking for adventure, relaxation, or cultural experiences, we've got you covered.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={servicesImage} alt="Services" className="img-fluid rounded" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
