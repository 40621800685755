import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./Components/Home/Home"
import About from "./Components/AboutUs/AboutUs"
import Contact from "./Components/ContactUs/ContactUs"
import DestinationPage from "./Components/DestinationPage";
import DestinationDetail from "./Components/DestinationDetail";
import 'bootstrap/dist/css/bootstrap.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Layout from './Layout';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/destinations" element={<DestinationPage />} />
      <Route path="" element={<Home />} />
      <Route path="/destination/:id" element={<DestinationDetail />} />

    </Route>
  )
)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastContainer />
    <RouterProvider router={router} />
  </React.StrictMode>
);


