import React from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import travelImage from '../../../assets/why-us2.png'; // Make sure this path is correct

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us container mt-5 p-4">
      <div className="row">
        <div className="col-md-6">
          <img src={travelImage} alt="Travel" className="img-fluid rounded" />
        </div>
        <div className="col-md-6">
          <h2 className="text-primary">Why Choose Us</h2>
          <p>
            At <strong>ONTRAVEL</strong>, we strive to provide exceptional travel experiences that cater to your unique preferences. Here are some reasons why you should choose us for your next adventure:
          </p>
          <ul className="list-unstyled">
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
              Experienced and friendly tour guides
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
              Customizable travel packages
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
              Competitive pricing
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
              24/7 customer support
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
              Safe and secure booking process
            </li>
          </ul>
          <p>
            Our mission is to make your travel dreams come true with hassle-free planning and expert guidance. Join us for an unforgettable journey filled with wonderful experiences and cherished memories.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
