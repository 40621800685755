// src/Components/AboutUs/Mission.js
import React from 'react';
import missionImage from '../../assets/KERALA.webp'; // Update the path to your image

const Mission = () => {
    return (
        <section className="section mission">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2>Our Mission</h2>
                        <h4>Striving for Excellence</h4>
                        <p>At ONTRAVEL, our mission is to provide unforgettable travel experiences that cater to your unique preferences. We believe in creating memories that last a lifetime by offering exceptional services and personalized tours.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={missionImage} alt="Mission" className="img-fluid rounded" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Mission;
