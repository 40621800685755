import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import BookingForm from '../../BookingForm';
import { motion } from 'framer-motion';


const BannerSlider = () => {
    // Array of objects containing text content for each slide
    const slidesContent = [
        {
            heading: "Let's Leave The Road, And Take The Travosy",
            description: 'Planning for a trip? We will organize your trip with the best places and within best budget!  ',
            imgUrl: 'https://assets.traveltriangle.com/blog/wp-content/uploads/2015/05/himachal-cover.jpg'
        },
        {
            heading: 'Plan Your Next Adventure',
            description: 'Explore the breathtaking landscapes of Himachal Pradesh. Book your trip now!',
            imgUrl: 'https://static.toiimg.com/thumb/msid-82825970,width-748,height-499,resizemode=4,imgsize-1193830/.jpg'
        },
        {
            heading: 'Plan Your Next Adventure',
            description: 'Explore the breathtaking landscapes of Himachal Pradesh. Book your trip now!',
            imgUrl: 'https://www.thetravelmojo.com/himachal-tour/images/himachal-banner_02.jpeg'
        }
    ];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false // Hide navigation arrows
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Slider {...settings}>
                {slidesContent.map((slide, index) => (
                    <div key={index} className="banner-slide">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="slide-content">
                                    <h2>{slide.heading}</h2>
                                    <p>{slide.description}</p>
                                    <div className="contact-info">
                                        <button className="btn btn-primary custom" onClick={() => window.location.href = "tel:+918629882193"}>
                                            Contact Us
                                        </button>


                                    </div>
                                    <div className="Banner-Footer"></div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="booking-form-inner">

                                    <BookingForm />
                                </div>
                            </div>
                        </div>
                        <div className="slide-background" style={{ backgroundImage: `url(${slide.imgUrl})` }}></div>
                    </div >
                ))
                }
            </Slider >
        </motion.div >
    );
}

export default BannerSlider;
