// src/Components/ContactUs/ContactUs.js
import React from 'react';
import PhoneEmail from './PhoneEmail';
import ContactForm from './ContactForm';
import './ContactUs.css';
import banneImage from '../../assets/international.webp';

const ContactUs = () => {
    return (
        <div className="contact-us">
            <header className="contact-header">
                <img src={banneImage} alt="Contact Us" className="contact-image" />
                <div className="overlay"></div>
                <div className="header-content">
                    <h1>Contact Us</h1>
                    <p>Feel free to get in touch with us for any inquiries or questions you may have.</p>
                </div>
            </header>
            <PhoneEmail />
            <ContactForm />
        </div>
    );
}

export default ContactUs;
